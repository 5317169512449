import React, { Component } from 'react';
import { NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export class Products extends Component {
    static displayName = Products.name;

    constructor(props) {
        super(props);
        this.state = { products: [], loading: true, newCustomerkName: '' };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }



    componentDidMount() {
        this.populateData();
    }

    deleteDeckt(id) {
        const requestOptions = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        };
        fetch('api/products/delete/' + id, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.populateData();
                this.setState({ newCustomerkName: "" });
            });

    }

    static renderTable(products, context) {


        return (
            <table className='table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product =>
                        <tr key={product.id}>
                            <td><NavLink tag={Link} className="text-dark" to={"/productdetails?id=" + product.id}>{product.name}</NavLink></td>
                            <td> <button className="btn btn-primary" onClick={context.deleteDeckt.bind(context, product.id)}>Delete</button></td>
                        </tr>
                    )}
                </tbody>
            </table>
        );
    }



    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Products.renderTable(this.state.products, this);

        return (
            <div>

                <h1 id="tabelLabel">Products</h1>

                {contents}
                <br />
                <h2>New Product</h2>
                <form onSubmit={this.handleSubmit}>
                    <label>
                        Name:
          <input type="text" className="form-control" value={this.state.newCustomerkName} onChange={this.handleChange} />
                    </label>
                    <br />
                    <input type="submit" className="btn btn-primary" value="Add" />
                </form>
            </div>
        );
    }

    async populateData() {
        const response = await fetch('api/products', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        });
        const data = await response.json();
        this.setState({ products: data, loading: false });
    }

    handleChange(event) {
        this.setState({ newCustomerkName: event.target.value });
    }



    handleSubmit(event) {
        event.preventDefault();
        const requestOptions = {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        };
        fetch('api/products/new/' + this.state.newCustomerkName, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.populateData();
                this.setState({ newCustomerkName: "" });
            });

    }



}
