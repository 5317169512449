import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
    tenant: '1b57ec44-dbe2-4386-a3c2-cdb65e5d5fdb',
    clientId: '8be24ac6-9035-4b82-80e1-abd137b29ab1',
    endpoints: {
        api: 'ed327347-8084-44ce-9c87-d88d2110f72d',
    },
    cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
    adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);