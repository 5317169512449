import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
import DateObject from "react-date-object";

export class FingerPrintDetails extends Component {
    static displayName = FingerPrintDetails.name;

  constructor(props) {
      super(props);

      if (new URLSearchParams(this.props.location.search).get("key")) {
          this.state = { newFingerPrint: false, fingerprintKey: new URLSearchParams(this.props.location.search).get("key"), loading: true};
      }
      else {
          this.state = { newFingerPrint: true, fingerprintKey: null, loading: true, loadingCustomers: true, loadingProducts: true  };
      }
      
      this.handleSubmit = this.handleSubmit.bind(this);
      this.keyChange = this.keyChange.bind(this);
      this.customerChange = this.customerChange.bind(this);
      this.productChange = this.productChange.bind(this);
      this.activeChange = this.activeChange.bind(this);
      this.trialChange = this.trialChange.bind(this);
      this.unlimitedChange = this.unlimitedChange.bind(this);
      this.expiresChange = this.expiresChange.bind(this);
      this.userCapChange = this.userCapChange.bind(this);
      this.userGoodwillChange = this.userGoodwillChange.bind(this);
  }

    componentDidMount() {
        this.populateCustomers();
        this.populateProducts();
        this.populateData();
    }


    static renderExisting(context) {
        return (


            <table className='table table-striped' aria-labelledby="tabelLabel">
                <tbody>
                    <tr >
                        <td>Key</td>
                        <td>{context.state.fingerprint.key}</td>
                    </tr>
                    <tr >
                        <td>Customer</td>
                        <td>{context.state.fingerprint.customer}</td>
                    </tr>
                    <tr >
                        <td>Product</td>
                        <td>{context.state.fingerprint.product}</td>
                    </tr>
                    <tr >
                        <td>Created</td>
                        <td>{context.state.fingerprint.created}</td>
                    </tr>
                    <tr >
                        <td>Created By</td>
                        <td>{context.state.fingerprint.createdBy}</td>
                    </tr>
                    <tr >
                        <td>Last Used</td>
                        <td>{context.state.fingerprint.lastUsed}</td>
                    </tr>
                    <tr >
                        <td>User Count</td>
                        <td>{context.state.fingerprint.userCount} / {context.state.fingerprint.userCap}</td>
                    </tr>
                    <tr>
                        <td>Active</td>
                        <td><input type="checkbox" checked={context.state.fingerprint.active} onChange={context.activeChange} /></td>
                    </tr>
                    <tr>
                        <td>Trial</td>
                        <td><input  type="checkbox" checked={context.state.fingerprint.trial} onChange={context.trialChange} /></td>
                    </tr>
                    <tr>
                        <td>Unlimited</td>
                        <td><input type="checkbox" checked={context.state.fingerprint.unlimited} onChange={context.unlimitedChange} /></td>
                    </tr>
                    <tr>
                        <td>Expire Date</td>
                        <td><input className="form-control" type="datetime-local" value={context.state.fingerprint.expires} onChange={context.expiresChange} /></td>
                    </tr>
                    <tr>
                        <td>User Cap</td>
                        <td><input className="form-control" type="text" value={context.state.fingerprint.userCap} onChange={context.userCapChange} /></td>
                    </tr>
                    <tr>
                        <td>User Goodwill</td>
                        <td><input className="form-control" type="text" value={context.state.fingerprint.userGoodwill} onChange={context.userGoodwillChange} /></td>
                    </tr>
                </tbody>
            </table>

        );
    }

    static renderNew(context) {
        return (


            <table className='table table-striped' aria-labelledby="tabelLabel">
                <tbody>
                    <tr >
                        <td>Key</td>
                        <td><input className="form-control"  type="text" value={context.state.fingerprint.key} onChange={context.keyChange} /></td>
                    </tr>
                    <tr >
                        <td>Customer</td>
                        <td>
                            <select className="form-control" name="customers" id="customers" onChange={context.customerChange} >
                            {context.state.customers.map(customer =>
                                <option className="form-control" value={customer.id}>{customer.name}</option>
                            )}
                            </select>
                        </td>
                    </tr>
                    <tr >
                        <td>Product</td>
                        <td>
                            <select className="form-control" name="products" id="products" onChange={context.productChange} >
                                {context.state.products.map(product =>
                                    <option className="form-control" value={product.id}>{product.name}</option>
                                )}
                            </select>
                        </td>
                    </tr>                 
                    <tr>
                        <td>Active</td>
                        <td><input  type="checkbox" checked={context.state.fingerprint.active} onChange={context.activeChange} /></td>
                    </tr>
                    <tr>
                        <td>Trial</td>
                        <td><input  type="checkbox" checked={context.state.fingerprint.trial} onChange={context.trialChange} /></td>
                    </tr>
                    <tr>
                        <td>Unlimited</td>
                        <td><input type="checkbox" checked={context.state.fingerprint.unlimited} onChange={context.unlimitedChange} /></td>
                    </tr>
                    <tr>
                        <td>Expire Date</td>
                        <td><input className="form-control" type="datetime-local" value={context.state.fingerprint.expires} onChange={context.expiresChange} /></td>
                    </tr>
                    <tr>
                        <td>User Cap</td>
                        <td><input className="form-control" type="text" value={context.state.fingerprint.userCap} onChange={context.userCapChange} /></td>
                    </tr>
                    <tr>
                        <td>User Goodwill</td>
                        <td><input className="form-control" type="text" value={context.state.fingerprint.userGoodwill} onChange={context.userGoodwillChange} /></td>
                    </tr>
                </tbody>
            </table>

        );
    }


    handleSubmit(event) {
        event.preventDefault();
        if (this.state.newFingerPrint) {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ Key: this.state.fingerprint.key, CustomerID: this.state.selectedCustomer, ProductID: this.state.selectedProduct, Active: this.state.fingerprint.active, Trial: this.state.fingerprint.trial, ExpireDate: this.state.fingerprint.expires, Unlimited: this.state.fingerprint.unlimited, UserCap: this.state.fingerprint.userCap, UserGoodwill: this.state.fingerprint.userGoodwill })
            };
            fetch('api/FingerPrints/New', requestOptions)
                .then(response => response.json())
                .then(result => {
                    window.open("./fingerprintsoverview", "_self");
                });
        }
        else {
            const requestOptions = {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('access_token'),
                    'Content-Type': 'application/json'
                }),
                body: JSON.stringify({ Key: this.state.fingerprint.key, Active: this.state.fingerprint.active, Trial: this.state.fingerprint.trial, ExpireDate: this.state.fingerprint.expires, Unlimited: this.state.fingerprint.unlimited, UserCap: this.state.fingerprint.userCap, UserGoodwill: this.state.fingerprint.userGoodwill })
            };
            fetch('api/FingerPrints/Modify', requestOptions)
                .then(response => response.json())
                .then(result => {
                    window.open("./fingerprintsoverview", "_self");
                });
        }

    }
    customerChange(event) {
        this.setState({ selectedCustomer: event.target.value });
    }
    productChange(event) {
        this.setState({ selectedProduct: event.target.value });
    }

    keyChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.key = event.target.value;
        this.setState({
            fingerprint: fingerprintCopy
        })
    }
    activeChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.active = event.target.checked;
        this.setState({
            fingerprint: fingerprintCopy
        })
    }
    trialChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.trial = event.target.checked;
        this.setState({
            fingerprint: fingerprintCopy
        })
    }
    unlimitedChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.unlimited = event.target.checked;
        this.setState({
            fingerprint: fingerprintCopy
        })
    }
    expiresChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.expires = event.target.value;
        this.setState({
            fingerprint: fingerprintCopy
        })
    }
    userCapChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.userCap = event.target.value;
        this.setState({
            fingerprint: fingerprintCopy
        })
    }
    userGoodwillChange(event) {
        let fingerprintCopy = JSON.parse(JSON.stringify(this.state.fingerprint))
        fingerprintCopy.userGoodwill = event.target.value;
        this.setState({
            fingerprint: fingerprintCopy
        }) 
    }

    render() {
        let contents = <p><em>Loading...</em></p>;

        if (this.state.newFingerPrint) {

            if (!this.state.loading && !this.state.loadingCustomers && !this.state.loadingProducts) {
                contents = FingerPrintDetails.renderNew(this)
            }
        }
        else {
            if (!this.state.loading) {
                contents = FingerPrintDetails.renderExisting(this)
            }
        }
    

    return (
      <div>
            <h1 id="tabelLabel" >FingerPrint Details</h1>
            <form onSubmit={this.handleSubmit}>
                {contents}
                <input type="submit" className="btn btn-primary" value="Save" />
            </form>
      </div>
    );
  }

    async populateData() {
        const response = await fetch('api/FingerPrints/' + this.state.fingerprintKey, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        });
        const data = await response.json();
        if (data) {
            this.setState({ fingerprint: data, loading: false });
        }
        else {
            let fp = {};
            fp.key = uuidv4();
            fp.active = true;
            fp.trial = false;
            fp.unlimited = false;
            var date = new DateObject();
            fp.expires = date.add(1, "years").format("YYYY-MM-DDTHH:mm:ss");
            fp.userCap = 100;
            fp.userGoodwill = 10;
            this.setState({ fingerprint: fp, loading: false });
        }
      
    }

    async populateCustomers() {
        const response = await fetch('api/customers', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        });
        const data = await response.json();
        this.setState({
            customers: data, loadingCustomers: false, selectedCustomer: data[0].id
        });
    }

 

    async populateProducts() {
        const response = await fetch('api/products', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        });
        const data = await response.json();
        this.setState({ products: data, loadingProducts: false, selectedProduct: data[0].id });
    }

   
}
