import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import './custom.css'
import { FingerPrints } from './components/FingerPrints';
import { FingerPrintDetails } from './components/FingerPrintDetails';
import { authContext, adalConfig } from './adalConfig';
import { runWithAdal } from 'react-adal';
import { Customers } from './components/Customers';
import { CustomerDetails } from './components/CustomerDetails';
import { Products } from './components/Products';
import { ProductDetails } from './components/ProductDetails';

const DO_NOT_LOGIN = false;

class App extends Component {

    state = {
        username: ''
    };

    componentDidMount() {

        runWithAdal(authContext, () => {
            // TODO : continue your process
            var user = authContext.getCachedUser();
            if (user) {
                console.log(user);
                console.log(user.userName);
                this.setState({ username: user.name });
                localStorage.setItem('username', user.profile.name)
            }
            else {
                // Initiate login
                // authContext.login();        
                console.log('getCachedUser() error');
            }

            var token = authContext.getCachedToken(adalConfig.clientId)
            if (token) {
                console.log(token);
                localStorage.setItem('access_token', token)
               
            }
            else {
                console.log('getCachedToken() error');
            }
        }, DO_NOT_LOGIN);
    }

    render() {
        return (
            <Layout>
                <Route exact path='/' component={Home} />
                <Route path='/counter' component={Counter} />
                <Route path='/fetch-data' component={FetchData} />
                <Route path='/fingerprintsoverview' component={FingerPrints} />
                <Route path='/fingerprintdetails' component={FingerPrintDetails} />
                <Route path='/customers' component={Customers} />
                <Route path='/customerdetails' component={CustomerDetails} />
                <Route path='/products' component={Products} />
                <Route path='/productdetails' component={ProductDetails} />
            </Layout>
        );
    }
}

export default App;