import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { NavLink } from 'reactstrap';


export class ProductDetails extends Component {
    static displayName = ProductDetails.name;

  constructor(props) {
      super(props);

      this.state = { fingerprints: [], loading: true, productID: new URLSearchParams(this.props.location.search).get("id") };
  }

  componentDidMount() {
    this.populateData();
    }



    static renderTable(fingerprints) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Customer</th>
            <th>Active</th>
            <th>Trial</th>
            <th>Expires</th>
            <th>Last Used</th>
            <th>Unlimited</th>
          </tr>
        </thead>
        <tbody>
                {fingerprints.map(fingerprint =>
                    <tr key={fingerprint.id}>
                        <td> <NavLink tag={Link} className="text-dark" to={"/fingerprintdetails?key="+fingerprint.key}>{fingerprint.customer}</NavLink></td>
                        <td><input disabled="true" type="checkbox" checked={fingerprint.active} /></td>
                        <td><input disabled="true" type="checkbox" checked={fingerprint.trial} /></td>
                        <td>{fingerprint.expires}</td>
                        <td>{fingerprint.lastUsed}</td>
                        <td><input disabled="true" type="checkbox" checked={fingerprint.unlimited}/></td>
                    </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
        : ProductDetails.renderTable(this.state.fingerprints);

    return (
      <div>
        <h1 id="tabelLabel" >Fingerprints</h1>
        {contents}
      </div>
    );
  }

    async populateData() {
        const response = await fetch('api/products/' + this.state.productID + '/fingerprints', {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('access_token')
            })
        });
    const data = await response.json();
      this.setState({ fingerprints: data, loading: false });
  }
}
